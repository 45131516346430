
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const searchQuery = ref<string>();
    const router = useRouter();

    const changeHandler = (e: any) => {
      e.preventDefault();
      searchQuery.value = e.target.value;
    };

    const onSubmit = (e: any) => {
      e.preventDefault();
      router.push(`/search/${searchQuery.value}`);
    };

    return { searchQuery, changeHandler, onSubmit };
  }
});
