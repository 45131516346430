
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import SearchBar from './SearchBar.vue';
import isUser from '../../context/user';
import User from '../../types/User';
import { verifier } from '../../code-thing';

export default defineComponent({
  components: { SearchBar },
  setup() {
    const router = useRouter();
    const userCheck = ref(false);
    const user = ref<User | null>(null);

    const loginUrl = ref<string | null>(null);

    const toggleSearchBar = () => {
      const searchBar = document.querySelector('.anime-searchbar-form') || null;

      if (searchBar && !searchBar.classList.contains('visible')) {
        searchBar.classList.add('visible');
      } else if (searchBar && searchBar.classList.contains('visible')) {
        searchBar.classList.remove('visible');
      }
    };
    isUser(userCheck);
    if (userCheck.value) {
      user.value = JSON.parse(localStorage.getItem('user') || '{}');
    }

    loginUrl.value = `https://myanimelist.net/v1/oauth2/authorize?response_type=code&client_id=${process.env.VUE_APP_X_MAL_CLIENT_ID}&code_challenge=${verifier}&state=RequestID42`;

    const saveVerifier = () => {
      if (localStorage.getItem('mal-verifier')) {
        localStorage.removeItem('mal-verifier');
        localStorage.setItem('mal-verifier', verifier);
      } else {
        localStorage.setItem('mal-verifier', verifier);
      }
      window.open(loginUrl.value!, '_self');
    };

    const handleLogout = () => {
      if (localStorage.getItem('user')) {
        localStorage.removeItem('user');
        localStorage.removeItem('mal-verifier');
      }
      router.push('/');
    };

    return { toggleSearchBar, userCheck, user, saveVerifier, handleLogout };
  }
});
