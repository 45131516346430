
import { defineComponent, ref } from 'vue';

import Header from '../components/header/Header.vue';
import Backdrop from '../components/home/Backdrop.vue';
import AnimeItem from '../components/home/AnimeItem.vue';
import Anime from '../types/Anime';
import { verifier } from '../code-thing';
import isUser from '../context/user';
import User from '../types/User';

export default defineComponent({
  components: { Header, Backdrop, AnimeItem },
  setup() {
    const suggestedAnime = ref<Anime[] | null>(null);
    const popularAnime = ref<Anime[] | null>(null);
    const topAiringAnime = ref<Anime[] | null>(null);
    const topRatedAnime = ref<Anime[] | null>(null);
    const seasonAnime = ref<Anime[] | null>(null);

    const loginUrl = ref<string | null>(null);
    const userCheck = ref(false);
    const user = ref<User | null>(null);

    let bgArray: [];
    const animeBackground = ref<string>('');

    isUser(userCheck);
    if (userCheck.value) {
      user.value = JSON.parse(localStorage.getItem('user') || '{}');
    }
    const fetchSuggestedAnime = async () => {
      try {
        if (user.value?.access_token) {
          const response = await fetch(
            `${process.env.VUE_APP_BACKEND_URL}/suggested/${user.value?.access_token}`
          );
          if (!response.ok) {
            throw Error('No data available');
          }
          const json = await response.json();
          const jsonObj = await JSON.parse(json);
          suggestedAnime.value = jsonObj.data;
        }
      } catch (error) {
        error.value = error.message;
        console.log('Error: ', error.value);
      }
    };

    fetchSuggestedAnime();

    const fetchMostPopularAnime = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/most-popular`);
        if (!response.ok) {
          throw Error('No data available');
        }
        const json = await response.json();
        const jsonObj = await JSON.parse(json);
        popularAnime.value = jsonObj.data;
      } catch (error) {
        error.value = error.message;
        console.log('Error: ', error.value);
      }
    };

    fetchMostPopularAnime();

    const fetchTopAiringAnime = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/top-airing`);
        if (!response.ok) {
          throw Error('No data available');
        }
        const json = await response.json();
        const jsonObj = await JSON.parse(json);
        topAiringAnime.value = jsonObj.data;
      } catch (error) {
        error.value = error.message;
        console.log('Error: ', error.value);
      }
    };

    fetchTopAiringAnime();

    const fetchTopRatedAnime = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/top-all`);
        if (!response.ok) {
          throw Error('No data available');
        }
        const json = await response.json();
        const jsonObj = await JSON.parse(json);
        topRatedAnime.value = jsonObj.data;
      } catch (error) {
        error.value = error.message;
        console.log('Error: ', error.value);
      }
    };

    fetchTopRatedAnime();

    const fetchThisSeasonAnime = async () => {
      try {
        const response = await fetch(`${process.env.VUE_APP_BACKEND_URL}/winter`);
        if (!response.ok) {
          throw Error('No data available');
        }
        const json = await response.json();
        const jsonObj = await JSON.parse(json);
        seasonAnime.value = jsonObj.data;
      } catch (error) {
        error.value = error.message;
        console.log('Error: ', error.value);
      }
    };

    fetchThisSeasonAnime();

    const getHomeBG = async () => {
      try {
        await fetch(`${process.env.VUE_APP_BACKEND_URL}/movieAPI/bg`)
          .then((res) => res.json())
          .then((res) => JSON.parse(res))
          .then((data) => {
            bgArray = data.map((a: any) => a.backdrop_path);
          });
      } catch (error) {
        error.value = error.message;
        console.log('Error: ', error.value);
      }
      const animeBackgroundUrl = await bgArray[Math.floor(Math.random() * bgArray.length)];
      animeBackground.value = `https://image.tmdb.org/t/p/w1920_and_h1080_multi_faces${animeBackgroundUrl}`;
    };

    getHomeBG();

    loginUrl.value = `https://myanimelist.net/v1/oauth2/authorize?response_type=code&client_id=${process.env.VUE_APP_X_MAL_CLIENT_ID}&code_challenge=${verifier}&state=RequestID42`;

    const saveVerifier = () => {
      if (localStorage.getItem('mal-verifier')) {
        localStorage.removeItem('mal-verifier');
        localStorage.setItem('mal-verifier', verifier);
      } else {
        localStorage.setItem('mal-verifier', verifier);
      }
      window.open(loginUrl.value!, '_self');
    };

    return {
      suggestedAnime,
      popularAnime,
      topAiringAnime,
      topRatedAnime,
      seasonAnime,
      animeBackground,
      loginUrl,
      saveVerifier,
      user,
      verifier
    };
  }
});
