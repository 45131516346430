import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-4" }
const _hoisted_2 = {
  className: "box-border text-black",
  for: "search"
}
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    className: "anime-searchbar-form bg-black-background h-auto mx-auto z-40 absolute pb-1 top-10 right-1.5 left-1.5 box-border text-black w-full items-center box-border lg:w-1200px",
    onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
  }, [
    _createElementVNode("fieldset", _hoisted_1, [
      _createElementVNode("label", _hoisted_2, [
        _createElementVNode("input", {
          class: "search w-full h-9 leading-10 text-1.1em text-black border-none rounded-3xl px-5 py-2.5 shadow box-border focus:outline-none",
          dir: "auto",
          name: "query",
          type: "text",
          tabIndex: "1",
          autoCorrect: "on",
          autoComplete: "on",
          spellCheck: "false",
          placeholder: "Search...",
          value: _ctx.searchQuery,
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeHandler && _ctx.changeHandler(...args)))
        }, null, 40, _hoisted_3)
      ])
    ])
  ], 32))
}